<template>
  <div>
    <v-row class="mt-3 pb-3">
      <v-col cols="12" sm="3" :class="$vuetify.breakpoint.xsOnly && 'mb-2'">
        <div class="d-flex align-center flex-wrap">
          <v-combobox
            v-if="!isGrade"
            :items="dataClassList"
            :loading="loadingClass"
            :label="$t('master_data.student.tab1_data.choose_class')"
            :item-text="concatString"
            dense
            item-value="id"
            outlined
            hide-details
            @change="changeClass"
          />
        </div>
      </v-col>
      <v-spacer />
      <v-col cols="12" sm="3" :class="$vuetify.breakpoint.xsOnly && 'mb-2'">
        <v-switch
          :label="isActive ? $t('set_time.active') : $t('set_time.no_active')"
          :disabled="dataSchedule.length == 0 ? true : false"
          v-model="isActive"
          hide-details
          inset
        />
      </v-col>
    </v-row>

    <v-data-table
      ref="dTable"
      :headers="tableHeaders"
      :items="dataSchedule"
      :loading="loading"
      :item-key="dataTableKey"
      :expanded.sync="itemClassExpanded"
      show-expand
      hide-default-footer
      class="elevation-0"
    >
      <template v-slot:item.action="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              :disabled="!isActive"
              class="mx-2 primary"
              icon
              dark
              v-on="on"
              small
              @click="setItemSave(item)"
            >
              <v-icon size="20">mdi-content-save</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("set_time.save") }}</span>
        </v-tooltip>
      </template>
      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <!-- <td v-if="item && item.length > 0" :colspan="headers.length"> -->
          <v-simple-table class="my-4">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">{{ $t("set_time.table.day") }}</th>
                  <th class="text-center">
                    {{ $t("set_time.table.lesson_hours") }}
                  </th>
                  <th class="text-left">{{ $t("set_time.table.status") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(d, idx) in item.schedule" :key="`${idx}-item`">
                  <td>
                    {{ g_language == "en" ? d.eng : d.ind }}
                  </td>
                  <td>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <div class="d-flex align-center">
                          <div class="pl-2">
                            <TimePicker
                              :label="$t('set_time.table.time_off_attendance')"
                              :outlined="true"
                              :dense="true"
                              :hide-details="true"
                              :isDisabled="!d.isDayOff || !isActive"
                              :removeValue="!d.isDayOff"
                              :initValue="
                                d.normal_in != null
                                  ? d.normal_in.substr(0, 5)
                                  : ''
                              "
                              @value="v => (d.normal_in = v)"
                            />
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <div class="d-flex align-center">
                          <div class="pl-2">
                            <TimePicker
                              :label="$t('set_time.table.time_away_home')"
                              :outlined="true"
                              :dense="true"
                              :hide-details="true"
                              :isDisabled="!d.isDayOff || !isActive"
                              :removeValue="!d.isDayOff"
                              :initValue="
                                d.normal_out != null
                                  ? d.normal_out.substr(0, 5)
                                  : ''
                              "
                              @value="v => (d.normal_out = v)"
                            />
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </td>
                  <td>
                    <v-switch
                      :label="
                        !d.isDayOff
                          ? $t('set_time.day_off')
                          : $t('set_time.day_in')
                      "
                      :disabled="!isActive"
                      v-model="d.isDayOff"
                      hide-details
                      @change="isDayOff(item, idx, d)"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
    </v-data-table>

    <ModalConfirm
      :title="$t('set_time.enter_school_schedule')"
      :close="() => (this.confirmSave = false)"
      :isOpen="confirmSave"
      :save="postData"
      :loading="loadingConfirm"
    >
      <div v-if="isGrade">
        <span v-html="$t('set_time.confirm_update_2')"></span>
        <span v-if="selectedItem">
          {{ selectedItem.grade }}
        </span>
        ?
      </div>
      <div v-else>
        <span v-html="$t('set_time.confirm_update_3')"></span>
        <span v-if="selectedItem">
          {{ selectedItem.class_name }}
        </span>
        ?
      </div>
    </ModalConfirm>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { mapGetters } from "vuex";
import { data_tiers } from "@/api/admin/academic/classList";
import { get_class_list } from "@/api/admin/schoolClass";
import {
  getGradeOrClass,
  updateGradeOrClass
} from "@/api/admin/absent/setTime";
import { get_school_year_list } from "@/api/admin/schoolClass";

export default {
  props: {
    init: {
      type: Boolean,
      required: true
    },
    isGrade: Boolean
  },
  components: {
    ModalConfirm: () => import("@/components/ModalConfirm"),
    TimePicker: () => import("@/components/TimePicker")
  },
  computed: {
    ...mapGetters(["g_language"]),
    dataTableKey() {
      const route = this.$route;
      return route.query.child_tab_student == 0 ||
        route.fullPath === "/master/set_time"
        ? "grade_id"
        : "class_id";
    }
  },
  async created() {
    if (this.isGrade) {
      this.dataGradeList = await data_tiers().then(r => r.data.data);
    } else {
      this.getClassList();
    }
  },
  watch: {
    init: {
      immediate: true,
      handler() {
        this.getData();
      }
    }
  },
  data() {
    return {
      dataGradeList: [],
      modelTime: null,
      dataSchedule: [],
      dataClassList: [],
      loading: false,
      loadingClass: false,
      dataAllList: {
        sort: "class_master",
        order: "DESC",
        free: "",
        search: "",
        grade: [],
        school_year: []
      },
      tableHeaders: [
        {
          text: i18n.t(
            this.isGrade ? "set_time.tiers" : "set_time.table.class"
          ),
          align: "left",
          value: this.isGrade ? "grade" : "class_name",
          width: 180
        },
        {
          text: this.$i18n.t("app.action"),
          sortable: false,
          value: "action",
          align: "center",
          width: 120
        }
      ],
      itemClassExpanded: [],
      queryData: {
        grade_id: "",
        class_id: "",
        limit: 100,
        page: 1
      },
      isActive: false,
      selectedItem: null,
      confirmSave: false,
      loadingConfirm: false
    };
  },
  methods: {
    setItemSave(item) {
      this.selectedItem = item;
      this.confirmSave = true;
    },
    async getClassList() {
      this.loadingClass = true;
      try {
        const response = await get_school_year_list(false);
        if (response.data.code) {
          let r = response.data.data;
          this.dataAllList.school_year = [r.find(d => d.status === 1).id];
          this.dataClassList = await get_class_list(this.dataAllList).then(
            r => r.data.data
          );
        }
      } catch (error) {
        console.error("getClassList()", error);
      }
      this.loadingClass = false;
    },
    postData() {
      this.loadingConfirm = true;
      updateGradeOrClass(this.isGrade, this.selectedItem)
        .then(res => {
          if (res.data.code) {
            this.$store.commit("CALL_SNACKBAR", {
              msg: this.$i18n.t("set_time.response.success_edit"),
              color: "success"
            });
            this.getData();
            this.confirmSave = false;
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.loadingConfirm = false;
        })
        .catch(() => (this.loadingConfirm = false));
    },
    isDayOff(itemGrade, indexDay, itemDay) {
      // 1 == day in, 0 == day off
      let indexSchedule = this.dataSchedule.indexOf(itemGrade);
      if (itemDay.is_active == 0) {
        this.dataSchedule[indexSchedule].schedule[indexDay].is_active = 1;
      } else {
        this.dataSchedule[indexSchedule].schedule[indexDay].is_active = 0;
      }
    },
    changeClass(item) {
      this.queryData.class_id = item ? item.id : "";
      this.queryData.grade_id = item ? item.grade : "";
      this.getData();
    },
    changeGrade(idGrade) {
      this.queryData.grade_id = idGrade ? idGrade : "";
      this.getData();
    },
    concatString: item => item.grade + " / " + item.name,
    getData() {
      this.dataSchedule = [];
      this.loading = true;
      getGradeOrClass(this.isGrade, this.queryData)
        .then(res => {
          this.loading = false;
          const r = res.data.data;
          r.data?.map(d => {
            d.schedule.map(v =>
              Object.assign(v, { isDayOff: v.is_active == 0 ? false : true })
            );
          });

          this.dataSchedule = r.data;
          if (this.dataTableKey === "class_id") {
            this.$nextTick(() => {
              r.data.map(r => {
                this.itemClassExpanded = [r];
              });
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>
